import styled from 'styled-components';

const StyledFooter = styled.footer`
  padding: 8px;
  text-align: center;
  font-size: 1.0rem;
  color: var(--light-text);
  margin: 2rem 0 1rem;
`;

const year = new Date().getFullYear();

export default function Footer() {
  return (
    <>
      <hr />
      <StyledFooter>
        {' '}
        Appbrella &copy; {year}. All rights reserved. All trademarks are property of their
        respective owners.
      </StyledFooter>
      ;
    </>
  );
}
