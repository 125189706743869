import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  flex: 0 0 25%;
  padding: 1rem;
  h3 {
    font-size: 1rem;
    font-weight: 700;
  }
  p {
    font-size: 0.9rem;
    color: var(--gray);
  }
  img {
    border-radius: 4px;
    width: 100%;
    border: 1px solid var(--gray);
  }
  .app-info {
    display: flex;
    justify-content: space-between;
  }
  button {
    line-height: 1rem;
    height: 2rem;
    padding: 0 12px;
  }
  .owned {
    background: var(--secondary);
    color: white;
    border: 0;
  }
`;

export default function SiteCard({ title, category, img, owned }) {
  return (
    <StyledDiv>
      <img src={img} alt={title} />
      <div className="app-info">
        <div className="app-title">
          <h3>{title}</h3>
          <p>{category}</p>
        </div>
        {owned ? (
          <button className="owned" type="button">
            Owned
          </button>
        ) : (
          <button type="button">+ Add</button>
        )}
      </div>
    </StyledDiv>
  );
}

SiteCard.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  owned: PropTypes.bool.isRequired,
};
