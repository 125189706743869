import React from 'react';
import styled from 'styled-components';
import ForCard from '../../components/ForCard';
import MailchimpForm from '../../components/MailchimpForm';
import CrossOut from '../../components/CrossOut';
import heroApps from '../../assets/images/hero-apps.jpg';
import FamilyIcon from '../../assets/images/icon-family.svg';
import WellnessIcon from '../../assets/images/icon-wellness.svg';
import GamingIcon from '../../assets/images/icon-gaming.svg';
import FAQ from '../../components/FAQ/FAQ';
import landingFaq from '../../assets/landingFaq';
import IconDisplay from '../../components/IconDisplay';
import AndroidLogo from '../../assets/images/logo-android.svg';
import AppleLogo from '../../assets/images/logo-apple.svg';
import WindowsLogo from '../../assets/images/logo-windows.svg';

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  hr {
    width: clamp(8rem, 50vw, 48rem);
    border-top: 1px solid var(--gray);
  }
`;

const HeroBanner = styled.section`
  padding: 4rem 0 4rem 0;  
  background: black;
  background-image: url(${heroApps});
  background-position: center;
  background-size: cover;
  color: white;
  text-align: center;
  width: 100%;
  h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.4;
    width: 100%;
    padding: 0;
    padding-bottom: 40px;
    margin: 0;
  }

  button {
    padding: 16px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 8px;
    background-color: var(--red);
  }

  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 2.25rem;
    }
  }
`;

const Features = styled.section`
  padding: 3.875rem 0 4.125rem;
  width: 100%;
  max-width: 1200px;
  margin: auto;

  .feature {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const ForEveryone = styled.section`
  background-color: #f1f1f1;
  padding: 40px 20px;
  width: 100%;

  h2 {
    margin-bottom: 2.75rem;
  }
  ul {
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
  }

  li {
    margin-bottom: 1.625rem;

    &:nth-child(odd) {
      align-self: flex-start;
    }
    &:nth-child(even) {
      align-self: flex-end;
    }
  }

  @media only screen and (max-width: 800px) {
    i:nth-child(odd), li:nth-child(even) {
      align-self: center;
    }
  }
`;

const forEveryoneInfo = [
  {
    icon: FamilyIcon,
    title: 'The Digital Family',
    text: 'Get unlimited premium and membership level access to all apps in our network for one low monthly price, and end the need to worry if your little ones are going to get bored with an app exactly 5 minutes after you buy it.',
  },
  {
    icon: WellnessIcon,
    title: 'The Health Seeker',
    text: "Whether you're focused on speeding up with cardio, toning up with weights in the gym, breathing in relaxing yoga poses, or getting out on the open road, Appbrella gives you all the apps you need today and an endless library of new ones to discover.",
  },
  {
    icon: GamingIcon,
    title: 'The Gamer',
    text: "Sick of watching ads in games every 30 seconds? Tired annoying in-game purchases? With Appbrella, these are a thing of the past because when signed into an app as an Appbrella customer, you're a premium member with full access.",
  },
];

const Platforms = styled.section`
  padding: 3.875rem 0 4.375rem;
  max-width: 45.9375rem;

  p {
    font-size: 1.5rem;
    text-align: center;
    color: var(--light-text);
  }

  .icon-container {
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
  }
`;

const FAQSection = styled.section`
  background-color: #f1f1f1;
  padding: 3.875rem 0 4.375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EarlyAccess = styled.section`
  padding: 3.875rem 0 4.375rem;
  max-width: 45.9375rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1.5rem;
    text-align: center;
    color: var(--light-text);
  }
  
  @media only screen and (max-width: 800px) { 
    p {
      font-size: 1.25rem;
    }
  }
`;

export default function Landing() {
  return (
    <LandingContainer>
      <HeroBanner>
        <h1>
          All of your family&apos;s
          <br />
          <span className="bordered">app</span> subscriptions under
          <br />
          one <span className="bordered">umbrella</span>
        </h1>
        <button type="button">Get Early Access</button>
      </HeroBanner>
      <Features className="features">
        <h2>Pay only one subscription to access all of our apps.</h2>
        <div className="feature">
            <CrossOut text="Ads" size={3.0} />
            <CrossOut text="Chat" size={3.0} />
            <CrossOut text="In-App Purchases" size={1.2} />
        </div>
      </Features>
      <ForEveryone>
        <h2>Appbrella is for everyone ...</h2>
        <ul>
          {forEveryoneInfo.map((card) => (
            <li key={card.title}>
              <ForCard icon={card.icon} title={card.title} text={card.text} key={card.title} />
            </li>
          ))}
        </ul>
      </ForEveryone>
      <Platforms>
        <h2>Plus, it works on all platforms.</h2>
        <p>You can manage multiple devices across multiple platforms under the same subscription</p>
        <div className="icon-container">
          <IconDisplay icon={AndroidLogo}/>
          <IconDisplay icon={AppleLogo}/>
          <IconDisplay icon={WindowsLogo}/>
        </div>
      </Platforms>
      <FAQSection>
        <h2>Frequently Asked Questions</h2>
        <FAQ faqList={landingFaq} />
      </FAQSection>
      <EarlyAccess>
        <h2>Get early access, join the waitlist.</h2>
        <p>
          Start saving money with early access to our beta launch and join the Appbrella family
          early.
        </p>
        <MailchimpForm />
      </EarlyAccess>
    </LandingContainer>
  );
}
