import styled from 'styled-components';
import appData from '../../assets/appData.json';
import SiteCard from '../../components/SiteCard';
import Grid from '../../components/Grid';

const StyledHeader = styled.h2`
  margin: 1rem 1rem 0 1rem;
`;

export default function Main() {
  return (
    <>
      <StyledHeader>Featured Apps</StyledHeader>
      <Grid>
        {appData.data.map((site) => (
          <SiteCard
            title={site.title}
            category={site.category}
            img={site.img}
            owned={site.owned}
            key={site.id}
          />
        ))}
      </Grid>
    </>
  );
}
