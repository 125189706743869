import React from 'react';
import styled from 'styled-components';
import ForCard from '../../components/ForCard';
import MailchimpForm from '../../components/MailchimpForm';
import heroApps from '../../assets/images/hero-apps.jpg';
import FAQ from '../../components/FAQ/FAQ';
import devFaq from '../../assets/devFaq';
import IconDisplay from '../../components/IconDisplay';
import ProfitIcon from '../../assets/images/icon-profit.svg';
import FindIcon from '../../assets/images/icon-findability.svg';
import InfinityIcon from '../../assets/images/icon-infinity.svg';
import FamilyIcon from '../../assets/images/icon-family.svg';
import WellnessIcon from '../../assets/images/icon-wellness.svg';
import GamingIcon from '../../assets/images/icon-gaming.svg';

const DevContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 2.0rem;
    font-weight: 700;
    text-align: center;
  }

  hr {
    width: clamp(8rem, 50vw, 48rem);
    border-top: 1px solid var(--gray);
  }
`;

const HeroBanner = styled.section`
  padding: 4rem 0 4rem 0;  
  background: black;
  background-image: url(${heroApps});
  background-position: center;
  background-size: cover;
  color: white;
  text-align: center;
  width: 100%;
  h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.4;
    width: 100%;
    padding: 0;
    padding-bottom: 40px;
    margin: 0;
  }

  button {
    padding: 16px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 8px;
    background-color: var(--red);
  }

  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 2.25rem;
    }
  }
`;

const Benefits = styled.section`
  padding: 3.875rem 0 4.125rem;
  width: 100%;
  max-width: 1200px;
  margin: auto;

  .icon-container {
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
  }
`;

const WhyCare = styled.section`
  background-color: #F1F1F1;
  padding: 40px 20px;
  width: 100%;

  h2 {
    margin-bottom: 2.75rem;
  }
  ul {
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
  }

  li {
    margin-bottom: 1.625rem;

    &:nth-child(odd) {
      align-self: flex-start;
    }
    &:nth-child(even) {
      align-self: flex-end;
    }
  }

  @media only screen and (max-width: 800px) {
    i:nth-child(odd), li:nth-child(even) {
      align-self: center;
    }
  }
`;

const WhyCareInfo = [
  {
    title: 'How does it work?',
    text: (
      <>
      <p>Just like a movie on Netflix or a song on Spotify... every time a user uses your app or site, you will get paid.</p>
      <p> Your app will simply let customers identify (i.e. &quot;login&quot;) as an Appbrella customer. Once verified, you will simply tell us every time that user is actively using your app.</p>
      </>
    )
  },
  {
  
    title: 'Right, but how does it “work?”',
    text: (
      <>
      <p>As a developer, you will enable your users to login to Appbrella via your app platform&apos;s in-app web browser.</p>
      <p>After the user is verified, the users information will be available to your code. Your app will simply remember the user and report back a &quot;session report&quot; every time the user is actively using your app.</p>
      </>
    )
  },
  {
    title: 'What apps qualify?',
    text: (
      <p>Any and all with the exception of certain in-appropriate or unacceptable content areas. Outside of those pretty standard restrictions, all other app types are welcome to join Appbrella.<br/>
      Make fitness apps...check!<br/>
      Make games...check!<br/>
      Make business apps...check!</p>
    )
  },
];

const Audience = styled.div`
  padding: 40px 0px;
  width: 80%;

  .icon-container {
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
  }
`

const FAQSection = styled.section`
  background-color: #F1F1F1;  
  padding: 3.875rem 0 4.375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EarlyAccess = styled.section`
  padding: 3.875rem 0 4.375rem;
  max-width: 45.9375rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1.5rem;
    text-align: center;
    color: var(--light-text);
  }
  
  @media only screen and (max-width: 800px) { 
    p {
      font-size: 1.25rem;
    }
  }
`;

export default function Developer() {
  return (
    <DevContainer>
      <HeroBanner>
        <h1>
          Hello <span className="bordered">Developers.</span><br />
          Appbrella is here for you.
        </h1>
        <button type="button">I have an app!</button>
      </HeroBanner>
      <Benefits className="benefits">
        <h2>Appbrella benefits app developers with no cost.</h2>
        <div className="icon-container">
          <IconDisplay icon={ProfitIcon} text="Increase revenue without ads"/>
          <IconDisplay icon={FindIcon} text="Increase exposure and downloads"/>
          <IconDisplay icon={InfinityIcon} text="Enabled with a simple code update"/>
        </div>
      </Benefits>
      <WhyCare>
        <h2>Why should you care?</h2>
        <ul>
          {WhyCareInfo.map((card) => (
            <li key={card.title}>
              <ForCard icon={card.icon} title={card.title} text={card.text} key={card.title} />
            </li>
          ))}
        </ul>
      </WhyCare>
      <Audience>
        <h2>Appbrella is for all developers, no matter your audience.</h2>
        <div className="icon-container">
          <IconDisplay icon={FamilyIcon} text="Digital Families"/>
          <IconDisplay icon={WellnessIcon} text="Health Seekers"/>
          <IconDisplay icon={GamingIcon} text="Gamers"/>
        </div>
      </Audience>
      <FAQSection>
        <h2>Frequently Asked Questions</h2>
        <FAQ faqList={devFaq} />
      </FAQSection>
      <EarlyAccess>
        <h2>Join the Appbrella family</h2>
        <p>
          Start earning money by getting on our network quickly, give us your email address and we&apos;ll get you started.
        </p>
        <MailchimpForm />
      </EarlyAccess>
    </DevContainer>
  );
}
