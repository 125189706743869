export default [
  {
    question: 'Do you have to be a sophisticated developer?',
    answer: (
      <>
      <p>
        Not at all! If you can build an app and enable your users to login to
        Appbrella, then you can join!
      </p>
      <p>
        If you&apos;re a smaller or first-time app developer... Appbrella is a great
        way to increase awareness, drive downloads and monetize your
        app.
      </p>
      <p>
        If you&apos;re a large development studio looking for ways to increase
        revenue... Appbrella is for you!
      </p>
      <p>
        Perhaps you&apos;re a fitness influencer that has taken the next step to
        create an app for your followers... Welcome aboard!
      </p>
      <p>
        No matter what you build or who your ideal customer is, Appbrella
        provides a way to increase discovery of your app and generate
        revenue outside of purely showing ads.
      </p>
      </>
    ),
  },
  {
    question: 'How does a developer make money?',
    answer: (
      <>
        <p>
          Similar to Netflix or Spotify, apps on Appbrella make money
          whenever Appbrella users actively use your app. After the initial
          easy setup in your app, after a user is verified, your app will forever
          make money each and every time that user actively uses your app
          without you having to do a thing!
        </p>
      </>
    ),
  },
  {
    question: 'How do I get my app onto Appbrella?',
    answer: (
      <>
        <p>
          Today, head back up to the top and submit your information above to
          let us know you&apos;re interested. Our team will reach out to you shortly
          thereafter to review your app.
        </p>
        <p>
          As mentioned above, there is an application process to insure your
          app and its content meet our customers&apos; standards.
        </p>
      </>
    ),
  },
  {
    question: 'How hard is it to set up Appbrella once approved?',
    answer: (
      <>
        <p>
          Very simple! Your app will give users the ability to use the in-app
          web browser to login to Appbrella using a standardized web page
          hosted by Appbrella. After logging in, your app can then access the
          user&apos;s Appbrella customer credentials.
        </p>
        <p>
          From there, your app will report back each of the user&apos;s sessions to
          an Appbrella api so that we can credit the usage to your app.
        </p>
      </>
    ),
  },
];
