import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../assets/appbrella-logo.svg';

const StyledHeader = styled.header`
  padding: 1.5rem 1.2rem;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid var(--light-gray);
  max-width: 1200px;
  margin: auto;

  nav {
    width: 100%;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    li {
      margin: 0 20px;
      padding: 0 8px 2px;
      &:not(:first-child) {
        margin-bottom: 8px;
        border-bottom: 2px solid transparent;
        transition: all 0.2s ease-in-out;
        &:hover {
          border-bottom: 2px solid var(--red);
        }
      }
    }
    a {
      color: var(--red);
      font-weight: 700;
      text-transform: uppercase;
      padding-bottom: 3px;
    }
  }
`;

const StyledLogo = styled.img`
  height: 66px;
  margin-right: 20px;
  padding: 3px 0;
`;

export default function Header() {
  return (
    <StyledHeader>
      <nav>
        <ul>
          <li>
            <NavLink to="/">
              <StyledLogo src={Logo} alt="Appbrella" className="logo" />
            </NavLink>
          </li>
          <li>
            <NavLink to="/developer">For Developers</NavLink>
          </li>
        </ul>
      </nav>
    </StyledHeader>
  );
}
