import PropTypes from 'prop-types';
import styled from 'styled-components';
import CrossSymbol from '../../assets/images/crossSymbol.svg';

const CrossedOut = styled.div`
  height: 174px;
  width: 174px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: ${(props) => props.size}rem;

  img {
    position: absolute;
  }

  span {
    text-align: center;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 800px) {
    margin: auto;
    width: 80px;
    height: 120px;
    font-size: ${(props) => props.size*0.5}rem;
    
    img {
      width: 100px;
      height: 100px;
    }
  }
`;

export default function CrossOut({ text, size }) {
  return (
    <>
      <CrossedOut aria-hidden="true" size={size}>
        <img src={CrossSymbol} alt={`no ${text}`} />
        <span>{text}</span>
      </CrossedOut>
      <span className="sr-only">No {text}</span>
    </>
  );
}

CrossOut.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};
