import React, { useState, useEffect } from 'react';
import MailChimpSubscribe from 'react-mailchimp-subscribe';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Alert from '../Alert';

const SignupForm = styled.form`
  display: flex;
  margin: 0 0 3rem;

  input[type='email'] {
    width: 18rem;
    border: 2px solid var(--gray);
    border-right: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 1rem;
    font-size: 1.25rem;
  }

  input[type='submit'] {
    width: 14rem;
    font-size: 1.375rem;
    color: white;
    background: var(--red);
    text-align: center;
    font-weight: bold;
    border: 2px solid var(--gray);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &:hover {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    
    input[type='email'], input[type='submit'] {
      margin-bottom: 10px;
      padding: 1rem;
      border: solid 2px var(--gray);
      border-radius: 8px;
      width: 100%;
    }
  }
`;

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  const handleChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleBetaSubmit = (evt) => {
    const validateEmail = (testingEmail) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(testingEmail));
    };
    evt.preventDefault();
    // setUserInput({ ...userInput, betaEmail: '' });
    if (email && validateEmail(email)) {
      onValidated({
        EMAIL: email,
      });
    }
  };

  useEffect(() => {
    if (status === 'success') setEmail('');
  }, [status]);

  return (
    <SignupForm onSubmit={(e) => handleBetaSubmit(e)}>
      {status === 'sending' && <div className="status">sending ...</div>}

      {status !== 'sending' && <Alert status={status}>{message}</Alert>}
      {status !== 'success' ? (
        <>
          <input
            type="email"
            name="betaEmail"
            area-label="Email"
            placeholder="Email"
            onChange={handleChange}
            value={email}
          />
          <input type="submit" value="I want to join beta" />
        </>
      ) : null}
    </SignupForm>
  );
};

export default function MailchimpForm() {
  const postUrl = `https://gmail.us5.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <>
      <MailChimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </>
  );
}

CustomForm.propTypes = {
  onValidated: PropTypes.func.isRequired,
  status: PropTypes.string,
  message: PropTypes.string,
};

CustomForm.defaultProps = {
  status: null,
  message: null,
};
