import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAlert = styled.div`
  background: gray;
`;

export default function Alert({ children }) {
  return (
    <StyledAlert>
      {/* <i className={iconClassName} /> */}
      {children}
    </StyledAlert>
  );
}

Alert.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Alert.defaultProps = {
  children: null,
};
