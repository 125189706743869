import PropTypes from 'prop-types';
import styled from 'styled-components';

const Icon = styled.div`
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;

  img {
    width: 100px;
    height: 100px;
    align-self: center;
  }
  p {
    font-weight: bold;
    font-size: 1.33rem;
    line-height: 1.5rem;
    color: var(--red);
    text-align: center;
  }
`;

export default function IconDisplay({ icon, text }) {
    return (
      <Icon>
        {icon && <img src={icon} alt={text}/>}
        <p>{text}</p>
      </Icon>
    );
  }
  

  IconDisplay.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string.isRequired,
  };
  
  IconDisplay.defaultProps = {
    icon: null,
  };
  