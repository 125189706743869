import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';

export default function Layout({ children, authenticated, authenticateUser }) {
  return (
    <>
      <Header authenticated={authenticated} authenticateUser={authenticateUser} />
      {children}
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  authenticated: PropTypes.bool.isRequired,
  authenticateUser: PropTypes.func.isRequired,
};
