import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import faqArrowRight from '../../assets/images/faqArrowRight.svg';
import faqArrowDown from '../../assets/images/faqArrowDown.svg';

const StyledDL = styled.dl`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin: auto;
  max-width: 1200px;
`;

const StyledQuestion = styled.dt`
  margin: 0;
  padding: 12px 0px;
  width: 100%;
  align-self: flex-start;
  border-top: 1px solid #ccc;

  button {
    border: none;
    font-size: 1.75rem;
    font-weight: 700;
  }

  span.open::before {
    content: url(${faqArrowRight});
    margin-left: -24px;
    padding-right: 8px;
  }
  span.closed::before {
    content: url(${faqArrowDown});
    margin-left: -34px;
    padding-right: 9px;
  }

  &:last-child {
    border-bottom: 1px solid #ccc;
  }

  @media only screen and (max-width: 800px) {
    button {
      font-size: 1.25rem;
    }
  }
`;

const StyledAnswer = styled.dd`
  font-weight: 400;
  margin: 0 0 1.625rem;

  i {
    color: var(--red);
  }

  p {
    margin: 0 0 0.5rem;
    padding: 10px 0px;
    font-size: 1.25rem;
  }
  ol {
    margin-left: 25px;
  }
`;

export default function FAQ({ faqList }) {
  const [openFAQ, setOpenFAQ] = useState(faqList.map(() => false));

  const setFaqStatus = (idx) => {
    const newOpenFAQ = [...openFAQ];
    newOpenFAQ[idx] = !newOpenFAQ[idx];
    setOpenFAQ(newOpenFAQ);
  };

  return (
    <StyledDL>
      {faqList.map((faq, idx) => (
        <React.Fragment key={faq.question}>
          <StyledQuestion>
            <button
              type="button"
              onClick={() => setFaqStatus(idx)}
              aria-expanded={faq}
              aria-controls={`faq${idx}_desc`}
            >
              <span className={openFAQ[idx] ? 'closed' : 'open'}>{faq.question}</span>
            </button>
          </StyledQuestion>
          {openFAQ[idx] && (
            <StyledAnswer>
              <p id={`faq${idx}_desc`} className="desc">
                {faq.answer}
              </p>
            </StyledAnswer>
          )}
        </React.Fragment>
      ))}
    </StyledDL>
  );
}

FAQ.propTypes = {
  faqList: PropTypes.arrayOf.isRequired,
};
