import PropTypes from 'prop-types';

import styled from 'styled-components';

const Card = styled.div`
  width: 716px;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  padding: 2.5rem;
  background-color: #fff;

  .card-header {
    display: flex;
    align-items: center;
  }

  h3 {
    color: var(--red);
    font-size: 2.3125rem;
    font-weight: 700;
    margin: 2.125rem;
    margin-left: 0;
  }

  img {
    margin-right: 40px;
    width: 100px;
    height: 100px;
  }

  p {
    font-size: 1.1875rem;
    color: var(--light-text);
  }

  @media only screen and (max-width: 800px) {
    width: 95%;
    height: auto;

    .card-header {
      flex-direction: column;
      width: 100%;

      h3 {
        margin: 10px 0px;
        text-align: center;
      }
    }
  }
`;

export default function ForCard({ icon, title, text }) {
  return (
    <Card>
      <div className="card-header">
        {icon && <img src={icon} alt={title} />}
        <h3>{title}</h3>
      </div>
      <p>{text}</p>
    </Card>
  );
}

ForCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

ForCard.defaultProps = {
  icon: null,
};
