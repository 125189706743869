export default [
  {
    question: 'What is Appbrella',
    answer: (
      <p>
        Glad you asked? Like most media these days, there is so much to choose from that we want the
        ability to &quot;get it all.&quot; Just like movies with Netflix or music with Spotify,
        Appbrella gives you unlimited premium and membership level access to every app in our
        network.
      </p>
    ),
  },
  {
    question: 'What kind of access do I get?',
    answer: (
      <>
        <p>
          The best kind...unlimited and complete. For every app in our network, whenever you use it
          as an Appbrella verified user, you get all the paid and firewalled content and
          capabilities of the app.
        </p>
        <p>So that means...</p>
        <p>
          <i>No more ads</i> if the app&apos;s paid members don&apos;t see ads.
        </p>
        <p>
          <i>Access to all the videos, lessons, games, episodes and other content</i> on an app
          without restriction.
        </p>
      </>
    ),
  },
  {
    question: 'How does it work?',
    answer: (
      <>
        <p>It&apos;s as easy as 1..2..3.</p>
        <ol>
          <li>You sign up on Appbrella&apos;s website and get a subscription.</li>
          <li>You install and use an app that&apos;s part of Appbrella&apos;s network.</li>
          <li>You use the apps Appbrella login button to sign into your Appbrella account.</li>
        </ol>
        <p>
          DONE! You&apos;ll forever more have full access to that app as long as you&apos;re a
          current Appbrella customer.
        </p>
      </>
    ),
  },
  {
    question: 'Does Appbrella make apps?',
    answer: (
      <>
        <p>Yes and no.</p>
        <p>
          Yes, we are working away on our own app that helps a customer search for apps, review apps
          and manage their account.
        </p>
        <p>
          But, no, we do not make the apps that are part of our network. We simply bring unlimited
          access to the universe of apps.
        </p>
      </>
    ),
  },
];
