import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
import Landing from './pages/Landing';
import Main from './pages/Main';
import Developer from './pages/Developer';

export default function App() {
  const [authenticated, setAuthenticated] = useState(false);

  const authenticateUser = () => {
    setAuthenticated(!authenticated);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Layout authenticated={authenticated} authenticateUser={authenticateUser}>
        <Switch>
          <Route exact path="/">
            {authenticated ? <Main /> : <Landing />}
          </Route>
          <Route path="/developer">
            <Developer />
          </Route>
        </Switch>
      </Layout>
    </HelmetProvider>
  );
}
